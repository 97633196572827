export interface IOptions {
  label: string | number
  value: string | number
}

export interface IAdjustSaldoResParams {
  page: number
  per_page: number
}

export interface IStyle {
  minWidth: string
  maxWidth: string
}

export interface IHeaderCell {
  title: number | string
  customStyle: IStyle
}

export enum EnumAlertMessage {
  WAITING_FOR_APPROVAL = "Permintaan adjust saldo telah dikirim oleh [name] tanggal [date] pukul [time]",
  APPROVE = "Permintaan adjust saldo telah diterima oleh [name] tanggal [date] pukul [time]",
  REJECT = "Permintaan adjust saldo telah ditolak oleh [name] tanggal [date] pukul [time]"
}

export interface IDataCell {
  value: number | string | boolean
  customStyle: IStyle
}

export enum EnumAdjustSaldoStatus {
  WAITING_FOR_APPROVAL = 'WAITING FOR APPROVAL',
  APPROVE = 'APPROVED',
  REJECT = 'REJECTED'
}
