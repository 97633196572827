import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store from '@/app/ui/store'
import { Pagination } from "@/domain/entities/Pagination";
import { ApprovalAdjustSaldo, ApprovalAdjustSaldoDetail, ApprovalAdjustSaldoList } from "@/domain/entities/ApprovalAdjustSaldo";
import { APPROVAL_ADJUST_SALDO_PAGINATION, EventBusConstants, Utils } from "@/app/infrastructures/misc";
import { ApprovalAdjustSaldoPresenter } from "../presenters/ApprovalAdjustSaldoPresenter";
import { container } from "tsyringe";
import Vue from 'vue'
import { RequestApprovalAdjustment } from "@/data/payload/api/ApprovalAdjustSaldoRequest";
import { EnumAdjustSaldoStatus } from "@/app/infrastructures/misc/Constants/adjustSaldoApproval";

export interface ApprovalAdjustSaldoState {
  isLoading: boolean
  pagination?: Pagination
  data?: ApprovalAdjustSaldo[]
}

export interface RequestApprovalAdjustmentPayload {
  id: number,
  status: string,
  reason: string
}

@Module({ namespaced: true, store, name: 'ApprovalAdjustSaldoController', dynamic: true })
class ApprovalAdjustSaldoController extends VuexModule implements ApprovalAdjustSaldoState {
  public presenter: ApprovalAdjustSaldoPresenter = container.resolve(ApprovalAdjustSaldoPresenter)
  public isLoading = false;
  public pagination = new Pagination(1, APPROVAL_ADJUST_SALDO_PAGINATION);
  public data = [new ApprovalAdjustSaldo()]
  public dataDetail = new ApprovalAdjustSaldoDetail()
  public paginationDetail = new Pagination(1, APPROVAL_ADJUST_SALDO_PAGINATION);
  public errRejected = ''


  @Action({ rawError: true})
  public getAll(params: Record<string, string | number | Date>): void {
    this.setLoading(true)
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )
    this.presenter.getAll(formattedParams)
    .then(resp => {
      this.setDataApproval(resp)
    })
    .catch(error => {
      Vue.notify({
        title: 'Fetch All Approval Adjustment Saldo',
        text:
          error.status === 400 || error.status === 422
            ? error.error.message.en
            : 'Something wrong',
        type: 'error',
        duration: 5000,
      })
    })
    .finally(() => this.setLoading(false))
  }

  @Action({ rawError: true})
  public get(params: Record<string, string | number | Date>): void {
    this.setLoading(true)
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )
    this.presenter.get(formattedParams)
    .then(resp => {
      this.setDataDetailApproval(resp)
    })
    .catch(error => {
      Vue.notify({
        title: 'Fetch data detail Approval Adjustment Saldo',
        text:
          error.status === 400 || error.status === 422
            ? error.error.message.en
            : 'Something wrong',
        type: 'error',
        duration: 5000,
      })
    })
    .finally(() => this.setLoading(false))
  }

  @Action({ rawError: true})
  public requestAdjustmentApproval(payload: RequestApprovalAdjustmentPayload): void {
    this.setLoading(true)
    this.presenter.requestAdjustmentApproval(
      new RequestApprovalAdjustment(
        payload.id,
        payload.status,
        payload.reason
      )
    )
    .then(() => {
      if(payload.status === EnumAdjustSaldoStatus.REJECT) {
        this.setErrRejected(EventBusConstants.REJECTED_APPROVAL_ADJUST_SALDO)
      }

      if(payload.status === EnumAdjustSaldoStatus.APPROVE) {
        this.setErrRejected(EventBusConstants.APPROVE_APPROVAL_ADJUST_SALDO)
      }

    })
    .catch(error => {
      Vue.notify({
        title: 'Failed to request adjustment saldo',
        text:
          error.status === 400 || error.status === 422
            ? error.error.message.en
            : 'Something wrong',
        type: 'error',
        duration: 5000,
      })
    })
    .finally(() => this.setLoading(false))
  }

  @Mutation
  public setLoading(bool: boolean): void {
    this.isLoading = bool
  }

  @Mutation
  private setDataApproval(dataApprovalAdjustSaldo: ApprovalAdjustSaldoList): void {
    this.data = dataApprovalAdjustSaldo.data as ApprovalAdjustSaldo[]
    this.pagination = dataApprovalAdjustSaldo.pagination as Pagination
  }

  @Mutation
  private setDataDetailApproval(dataApprovalAdjustSaldoDetail: ApprovalAdjustSaldoDetail): void {
    this.dataDetail = dataApprovalAdjustSaldoDetail as ApprovalAdjustSaldoDetail
    this.paginationDetail = dataApprovalAdjustSaldoDetail.pagination as Pagination
  }

  @Mutation
  public setErrRejected(str: string): void {
    this.errRejected = str
  }
}

export default getModule(ApprovalAdjustSaldoController)
