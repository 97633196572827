






































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import Button from '@/app/ui/components/Button/index.vue'
import DataTableV2 from '@/app/ui/components/DataTableV2/index.vue'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import WarningIcon from '@/app/ui/assets/icon_warning_circle.vue'
import SuccessIcon from '@/app/ui/assets/success_icon_alt.vue'
import DownloadIcon from '@/app/ui/assets/icon_download_filled.vue'
import BackIcon from '@/app/ui/assets/icon_back.vue'
import EmptyIllustration from '@/app/ui/assets/EmptyState/no_data-paket.vue'
import IconWaitingForApproval from '@/app/ui/assets/clock.vue'
import IconApprove from '@/app/ui/assets/icon_check_circle_thin.vue'
import IconReject from '@/app/ui/assets/close_circle.vue'
import { Utils } from '@/app/infrastructures/misc/Utils'
import ModalSuccess from '../../components/Modals/ModalSuccess/index.vue'
import Modal from '@/app/ui/components/Modal/index.vue'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import ModalFailed from '../../components/Modals/ModalFailed/index.vue'
import { Validations } from 'vuelidate-property-decorators'
import { required, maxLength, minLength } from 'vuelidate/lib/validators'
import IconModalReject from '@/app/ui/assets/icon_check_circle.vue'
import controller from '@/app/ui/controllers/ApprovalAdjustSaldoController'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'

import {
  EnumAlertMessage,
  IDataCell,
  IHeaderCell,
  IOptions,
  IAdjustSaldoResParams,
  EnumAdjustSaldoStatus,
} from '@/app/infrastructures/misc/Constants/adjustSaldoApproval'
import { RowsApproval } from '@/domain/entities/ApprovalAdjustSaldo'
import { EventBusConstants } from '@/app/infrastructures/misc'
import { validationMixin } from 'vuelidate'

interface IValidations {
  reason: unknown
}

interface ApprovalAdjustSaldoDetail {
  dataUpload?: {
    filepath?: string
    filename?: string
    summary?: {
      courierValid?: number
      courierInvalid?: number
      rowValid?: number
      rowInvalid?: number
      amountValid?: {
        addition?: number
        reduction?: number
      }
      amountInvalid?: {
        addition?: number
        reduction?: number
      }
    }
    rows?: Array<RowsApproval>
  }
  createdAt?: string | Date
  createdBy?: string
  updatedAt?: string | Date
  updatedBy?: string
  status?: string
  reason?: string
}

@Component({
  mixins: [validationMixin],
  components: {
    Button,
    DataTableV2,
    PaginationNav,
    WarningIcon,
    SuccessIcon,
    DownloadIcon,
    BackIcon,
    EmptyIllustration,
    IconApprove,
    IconWaitingForApproval,
    IconReject,
    ModalSuccess,
    Modal,
    TextInput,
    ModalFailed,
    IconModalReject,
    LoadingOverlay,
  },
})
export default class ManualAdjustSaldoUploadBulkyPage extends Vue {
  controller = controller
  enumAlertMessage = EnumAlertMessage
  adjustSaldoStatus = EnumAdjustSaldoStatus
  Utils = Utils
  filename = ''
  reasonRejected = ''
  reason = ''
  showModalSuccess = false
  showModalConfirmation = false
  showModalFailed = false
  status = ''
  showModalFailedConnection = false
  createdAt: string | Date = ''
  updatedAt: string | Date = ''
  updatedBy = ''
  createdBy = ''
  data: Array<Array<string | number | IDataCell>> = []
  rows = {
    rowValid: 0,
    rowInvalid: 0,
  }
  courier: Record<string, number> = {
    courierValid: 0,
    courierInvalid: 0,
  }
  amountValid: Record<string, number> = {
    addition: 0,
    reduction: 0,
  }
  amountInvalid: Record<string, number> = {
    addition: 0,
    reduction: 0,
  }
  paginationOptions: Array<IOptions> = [
    { label: '10', value: 10 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
  ]
  parameters: IAdjustSaldoResParams = {
    page: 1,
    per_page: 10,
  }
  headers: Array<string | IHeaderCell> = [
    this.headerCellMapper('Baris', '5%'),
    this.headerCellMapper('ID Kurir', '15%'),
    this.headerCellMapper('Jumlah', '15%'),
    this.headerCellMapper('Catatan', '50%'),
    this.headerCellMapper('Status', '15%'),
  ]

  created(): void {
    this.getApprovalAdjustSaldoDetail()
  }

  mounted(): void {
    this.onSetDisplayBreadcrumb(false)
    window.addEventListener('offline', this.onHandleOffline)
  }

  get linkSourceFile(): string {
    return controller.dataDetail.dataUpload?.filepath || ''
  }

  get isManualAdjustSaldo(): boolean {
    return this.$route.fullPath.includes('manual-adjust-saldo')
  }

  private getApprovalAdjustSaldoDetail(reset = false): void {
    if (reset ) {
      this.parameters.page = 1
    }

    const param = {
      id: this.$route.params.id,
      page: this.parameters.page,
      per_page: this.parameters.per_page,
    }

    controller.get(param)
  }

  private onChangePaginationOption(perPageAmount: number): void {
    this.parameters.per_page = perPageAmount
    this.getApprovalAdjustSaldoDetail(true)
  }

  private tableCellMapper(
    value: string | number | boolean,
    colWidth: string
  ): IDataCell {
    return {
      value: value,
      customStyle: {
        minWidth: colWidth,
        maxWidth: colWidth,
      },
    }
  }

  private headerCellMapper(
    title: string | number,
    colWidth: string
  ): IHeaderCell {
    return {
      title: title,
      customStyle: {
        minWidth: colWidth,
        maxWidth: colWidth,
      },
    }
  }

  private formatAmount(amount?: number): string {
    if (amount) {
      if (amount < 0) {
        return `-Rp${(amount * -1).toLocaleString('id-ID')}`
      } else if (amount >= 0) {
        return `+Rp${amount.toLocaleString('id-ID')}`
      }
    }

    return '0'
  }

  private coloringStatus(status: string): string {
    let result = ''
    switch (status) {
      case this.adjustSaldoStatus.APPROVE:
        result = 'bg-green-1 text-green-2'
        break
      case this.adjustSaldoStatus.REJECT:
        result = 'bg-red-5 text-red-1'
        break
      case this.adjustSaldoStatus.WAITING_FOR_APPROVAL:
        result = 'bg-orange-100 text-orange-2'
        break
    }
    return result
  }

  private formatedStatus(status: string): string {
    let result = ''
    switch (status) {
      case this.adjustSaldoStatus.APPROVE:
        result = 'Terima'
        break
      case this.adjustSaldoStatus.REJECT:
        result = 'Tolak'
        break
      case this.adjustSaldoStatus.WAITING_FOR_APPROVAL:
        result = 'Menunggu'
        break
    }
    return result
  }

  private formatedStatusSummary(status: string): string {
    let result = ''
    switch (status) {
      case this.adjustSaldoStatus.APPROVE:
        result =
          'Permintaan adjust saldo telah diterima oleh' +
          ` <span class="font-bold">${this.updatedBy || '-'}</span>` +
         ` tanggal <span class="font-bold">${
            this.updatedAt
              ? Utils.formatDateWithIDLocale(
                  <string>this.updatedAt,
                  'dddd D MMMM YYYY'
                )
              : '-'
          }</span>` +
          ` pukul <span class="font-bold">${
            this.updatedAt
              ? Utils.formatDateWithIDLocale(<string>this.updatedAt, 'HH:mm')
              : '-'
          }</span>`
        break
      case this.adjustSaldoStatus.REJECT:
        result =
          'Permintaan adjust saldo telah ditolak oleh' +
          ` <span class="font-bold">${this.updatedBy || '-'}</span>` +
          ` tanggal <span class="font-bold">${
            this.updatedAt
              ? Utils.formatDateWithIDLocale(
                  <string>this.updatedAt,
                  'dddd D MMMM YYYY'
                )
              : '-'
          }</span>` +
          ` pukul <span class="font-bold">${
            this.updatedAt
              ? Utils.formatDateWithIDLocale(<string>this.updatedAt, 'HH:mm')
              : '-'
          }</span>`
        break
      case this.adjustSaldoStatus.WAITING_FOR_APPROVAL:
        result =
          'Permintaan adjust saldo telah dikirim oleh' +
          ` <span class="font-bold">${this.createdBy || '-'}</span>` +
          ` tanggal <span class="font-bold">${
            this.createdAt
              ? Utils.formatDateWithIDLocale(
                  <string>this.createdAt,
                  'dddd D MMMM YYYY'
                )
              : '-'
          }</span>` +
          ` pukul <span class="font-bold">${
            this.createdAt
              ? Utils.formatDateWithIDLocale(<string>this.createdAt, 'HH:mm')
              : '-'
          }</span>`
        break
    }
    return result
  }

  private onClickReject() {
    this.showModalConfirmation = false
    const payload = {
      id: Number(this.$route.params.id),
      status: this.adjustSaldoStatus.REJECT,
      reason: this.reason,
    }

    controller.requestAdjustmentApproval(payload)
  }

  private onClickApprove() {
    const payload = {
      id: Number(this.$route.params.id),
      status: this.adjustSaldoStatus.APPROVE,
      reason: '',
    }

    controller.requestAdjustmentApproval(payload)
  }

  private onSetDisplayBreadcrumb(isDisplayed: boolean): void {
    const breadcrumb = document.getElementById('breadcrumb')
    if (breadcrumb) {
      if (isDisplayed) {
        breadcrumb.style.display = 'flex'
      } else {
        breadcrumb.style.display = 'none'
      }
    }
  }

  private onHandleOffline(): void {
    if (controller.isLoading) {
      controller.setLoading(false)
      this.showModalConfirmation = false
      this.showModalFailedConnection = true
    }
  }

  private onDownloadTemplate(): void {
    if (this.linkSourceFile)
      window.open(
        process.env.VUE_APP_STORAGE_BASE_URL + '/' + this.linkSourceFile
      )
  }

  private onBackToListPage(): void {
    if (this.isManualAdjustSaldo) {
      this.$router.push({ name: 'ManualAdjustSaldoList' })
    } else {
      this.$router.push({ name: 'AdjustSaldoApprovalListPage' })
    }
  }

  @Validations()
  validations(): IValidations {
    return {
      reason: {
        required,
        maxLength: maxLength(255),
        minLength: minLength(10),
      },
    }
  }

  @Watch('controller.dataDetail')
  onWatchDataDetail(value: ApprovalAdjustSaldoDetail): void {
    const dataUpload = value.dataUpload
    if (!dataUpload) return

    const summary = dataUpload.summary
    if (!summary) return

    this.filename = dataUpload.filename || ''
    this.createdAt = value.createdAt || ''
    this.createdBy = value.createdBy || ''
    this.updatedAt = value.updatedAt || ''
    this.updatedBy = value.updatedBy || ''
    this.status = value.status || ''
    this.courier.courierInvalid = summary.courierInvalid || 0
    this.courier.courierValid = summary.courierValid || 0
    this.rows.rowValid = summary.rowValid || 0
    this.rows.rowInvalid = summary.rowInvalid || 0
    this.amountValid.addition = summary.amountValid?.addition || 0
    this.amountValid.reduction = summary.amountValid?.reduction || 0
    this.amountInvalid.addition = summary.amountInvalid?.addition || 0
    this.amountInvalid.reduction = summary.amountInvalid?.reduction || 0
    this.reasonRejected = value.reason || ''

    this.data =
      dataUpload.rows?.map((item: RowsApproval, index: number) => {
        return [
          this.tableCellMapper(
            (this.parameters.page - 1) * this.parameters.per_page + index + 1,
            '5%'
          ),
          this.tableCellMapper(String(item.courierId), '15%'),
          this.tableCellMapper(
            item.amount ? this.formatAmount(Number(item.amount)) : 0,
            '15%'
          ),
          this.tableCellMapper(item.note || '', '50%'),
          this.tableCellMapper(item.status || '', '15%'),
        ]
      }) || []
  }

  @Watch('controller.errRejected')
  onWatchRequest(value: string): void {
    if (value === EventBusConstants.REJECTED_APPROVAL_ADJUST_SALDO) {
      this.showModalFailed = true
      this.controller.setErrRejected('')
    }

    if (value === EventBusConstants.APPROVE_APPROVAL_ADJUST_SALDO) {
      this.showModalSuccess = true
      this.controller.setErrRejected('')
    }
  }

  beforeDestroy(): void {
    this.onSetDisplayBreadcrumb(true)
    window.removeEventListener('offline', this.onHandleOffline)
  }
}
