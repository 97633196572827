import { RequestApprovalAdjustmentInterface } from "@/data/payload/contracts/ApprovalAdjustSaldoRequest";

export class RequestApprovalAdjustment implements RequestApprovalAdjustmentInterface {
  public id?: number
  public status?: string
  public reason?: string

  constructor(
    id?: number,
    status?: string,
    reason?: string
  ) {
    this.id = id
    this.status = status
    this.reason = reason
  }

  toPayload(): string {
      const data = {
        id: this.id,
        status: this.status,
        reason: this.reason
      }
      return JSON.stringify(data)
  }
}
